import React from 'react';
import { useSelector } from 'react-redux';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Content from 'react-bulma-companion/lib/Content';

// import SessionOne from '_components/organisms/SessionOne';
// import SessionTwo from '_components/organisms/SessionTwo';

export default function SchedulePage() {
  const language = useSelector(state => state.language.data.schedule);

  return (
    <div className="schedule-page">
      <Section>
        <Title className="app-title" size="1" component="h1">{language.title}</Title>
      </Section>
      <hr />
      <Section>
        <Container>
          <Title size="2"><u>{language.days}</u></Title>
          <Title size="4">{language['fast-action']}: 6:30 p.m.</Title>
          <Title size="4">{language.xlt}: 7:00 p.m.</Title>
          <Title size="3">{language.session1}: 7:20 p.m. - 8:20 p.m.</Title>
          <br />
          {/* <SessionOne /> */}
          <Title size="4">{language['fast-action']}: 8:30 p.m.</Title>
          <Title size="3">{language.session2}: 8:50 p.m. - 9:50 p.m.</Title>
          <br />
          {/* <SessionTwo /> */}
          <Content size="small">
            <p>{language.updated} November 17, 2022.</p>
          </Content>
        </Container>
      </Section>
    </div>
  );
}
