import ReactGA from 'react-ga';
import languages from '../locale';

export const SET_LANGUAGE = 'SET_LANGUAGE';

const getLanguage = languageCode => Promise.resolve(languages[languageCode]);

export function setLanguage(data, languageCode) {
  return {
    type: SET_LANGUAGE,
    data,
    languageCode,
  };
}

export const attemptSetLanguage = languageCode => dispatch =>
  getLanguage(languageCode)
    .then(data => dispatch(setLanguage(data, languageCode)))
    .then(() => ReactGA.event({ category: 'Language', action: 'Language Changed', label: languageCode }));
