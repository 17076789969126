import React from 'react';
import { useSelector } from 'react-redux';

import RBCFooter from 'react-bulma-companion/lib/Footer';
import Container from 'react-bulma-companion/lib/Container';
import Content from 'react-bulma-companion/lib/Content';

export default function Footer() {
  const { copyright, rights, created } = useSelector(state => state.language.data.footer);
  const year = new Date().getFullYear();

  return (
    <RBCFooter>
      <Container>
        <Content textAlign="center">
          <p>
            {copyright} &#169; {year} Lucky Lady Bingo Lessor Assocation. {rights}.
          </p>
          <p>
            {created} <a href="http://github.com/djizco">Rico Rojas</a>.
          </p>
        </Content>
      </Container>
    </RBCFooter>
  );
}
