import update from 'immutability-helper';
import { SET_LANGUAGE } from '_store/actions/language';

export default function language(state = { current: 'EN', data: {} }, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return update(state, {
        current: { $set: action.languageCode },
        data: { $set: action.data },
      });
    default:
      return state;
  }
}
