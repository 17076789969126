import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Content from 'react-bulma-companion/lib/Content';

export default function Schedule() {
  const language = useSelector(state => state.language.data);

  return (
    <Section className="schedule-section">
      <Container>
        <Title size="1" component="h1">{language.home.open}</Title>
        <Content>
          <p>
            {language.schedule.session1}: 7:20 p.m.
            <br />
            {language.schedule.session2}: 8:50 p.m.
          </p>
          <Link to="/schedule">
            <p>{language.home['check-session']}</p>
          </Link>
        </Content>
      </Container>
    </Section>
  );
}
