import React from 'react';

export default function CharitiesSection() {
  return (
    <div className="section charities-section">
      <p><a href="http://www.resistenciabooks.com/">Red Salmon Arts</a></p>
      <p><a href="https://www.texvet.org/resources/vfw-post-856">Veterans of Foreign Wars, Post 856</a></p>
      <p><a href="https://www.vfw.org/">Veterans of Foreign Wars, Post 10376, Marble Falls</a></p>
      <p><a href="https://www.lhcf.org/">Latino HealthCare Forum</a></p>
    </div>
  );
}
