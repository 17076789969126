import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';

export function Address() {
  return (
    <Columns activate="mobile">
      <Column size="one-quarter" className="icon-container">
        <span className="icon is-medium">
          <FontAwesomeIcon icon={faMapPin} />
        </span>
      </Column>
      <Column size="three-quarters" className="text-container">
        <p>
          724 Eberhart Ln.<br />
          Austin, Texas<br />
          78745
        </p>
      </Column>
    </Columns>
  );
}

export function Telephone() {
  return (
    <Columns activate="mobile">
      <Column size="one-quarter" className="icon-container">
        <span className="icon is-medium">
          <FontAwesomeIcon icon={faPhone} />
        </span>
      </Column>
      <Column size="three-quarters" className="text-container">
        <p><a href="tel:+1-512-467-4696">(512) 467-4696</a></p>
      </Column>
    </Columns>
  );
}

export function Email() {
  return (
    <Columns activate="mobile">
      <Column size="one-quarter" className="icon-container">
        <span className="icon is-medium">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
      </Column>
      <Column size="three-quarters" className="text-container">
        <p><a href="mailto:luckyladybingo@gmail.com">luckyladybingo@gmail.com</a></p>
      </Column>
    </Columns>
  );
}

export default function ContactInfo() {
  return (
    <div className="contact-info">
      <Address />
      <hr />
      <Telephone />
      <hr />
      <Email />
    </div>
  );
}
