import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { Routes, Route, useLocation } from 'react-router-dom';

import { attemptSetLanguage } from '_store/actions/language';

import Navigation from '_components/organisms/Navigation';
import Footer from '_components/organisms/Footer';
import HomePage from '_components/pages/HomePage';
import SchedulePage from '_components/pages/SchedulePage';
import CharitiesPage from '_components/pages/CharitiesPage';
import ContactPage from '_components/pages/ContactPage';
import LostPage from '_components/pages/LostPage';

export default function Main() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const currentLanguage = useSelector(state => state.language.current);

  useEffect(() => {
    dispatch(attemptSetLanguage(currentLanguage))
      .then(() => setLoading(false));
  }, [currentLanguage, dispatch]);

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return !loading && (
    <div className="has-navbar-fixed-top">
      <Navigation pathname={pathname} />
      <div className="main">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/charities" element={<CharitiesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<LostPage />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
