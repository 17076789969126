import React from 'react';
import { useSelector } from 'react-redux';

import Section from 'react-bulma-companion/lib/Section';
import Title from 'react-bulma-companion/lib/Title';

import CharitiesSection from '_components/sections/CharitiesSection';

export default function CharitiesPage() {
  const language = useSelector(state => state.language.data.charities);

  return (
    <div className="charities-page">
      <Section>
        <Title size="1">{language.title}</Title>
        <p>{language.giving}</p>
      </Section>
      <hr />
      <CharitiesSection />
    </div>
  );
}
