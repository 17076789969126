import React from 'react';

import WelcomeSection from '_components/sections/Welcome';
import ScheduleSection from '_components/sections/Schedule';
import ContactSection from '_components/sections/ContactSection';

export default function HomePage() {
  return (
    <div className="home-page parallax-page">
      <WelcomeSection />
      <ScheduleSection />
      <ContactSection />
    </div>
  );
}
