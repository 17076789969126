import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga';
import R from 'ramda';

import Navbar from 'react-bulma-companion/lib/Navbar';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';
import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Select from 'react-bulma-companion/lib/Select';

import { attemptSetLanguage } from '_store/actions/language';

export default function Navigation() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);
  const { schedule, charities, contact, language } = useSelector(state => state.language.data.navigation);
  const currentLanguage = useSelector(state => state.language.current);

  const deactivate = () => setActive(false);

  useEffect(() => {
    window.addEventListener('resize', deactivate);

    return () => {
      window.removeEventListener('resize', deactivate);
    };
  }, []);

  const toggleActive = () => {
    if (window.innerWidth <= 1023) {
      setActive(!active);
    }
  };

  const selectLanguage = e => {
    dispatch(attemptSetLanguage(e.target.value));
    ReactGA.event({ category: 'Select', action: 'Navigation - Select Language', label: e.target.value });
  };

  const isSchedule = (pathname.length === 9)
    ? pathname === '/schedule'
    : R.slice(0, 10, pathname) === '/schedule/';

  const isCharities = (pathname.length === 10)
    ? pathname === '/charities'
    : R.slice(0, 11, pathname) === '/charities/';

  const isContact = (pathname.length === 8)
    ? pathname === '/contact'
    : R.slice(0, 9, pathname) === '/contact/';

  return (
    <Navbar fixed="top" color="dark">
      <Container>
        <Navbar.Brand>
          <Navbar.Item to="/" component={Link}>
            <Title component="h6" size="3">
              Lucky Lady Bingo
            </Title>
          </Navbar.Item>
          <Navbar.Burger active={active} onClick={toggleActive} onKeyPress={toggleActive} />
        </Navbar.Brand>
        <Navbar.Menu active={active}>
          <Navbar.Start>
            <Navbar.Item component={Link} to="/schedule" onClick={toggleActive} active={isSchedule} tab>
              <Title component="h6" size="6">{schedule}</Title>
            </Navbar.Item>
            <Navbar.Item component={Link} to="/charities" onClick={toggleActive} active={isCharities} tab>
              <Title component="h6" size="6">{charities}</Title>
            </Navbar.Item>
            <Navbar.Item component={Link} to="/contact" onClick={toggleActive} active={isContact} tab>
              <Title component="h6" size="6">{contact}</Title>
            </Navbar.Item>
          </Navbar.Start>
          <Navbar.End>
            <Navbar.Item>
              <Title component="h6" size="6">{language}:</Title>
              <Field className="language-field">
                <Control>
                  <Select>
                    <Select.Content value={currentLanguage} onChange={selectLanguage}>
                      <Select.Option value="EN">English</Select.Option>
                      <Select.Option value="ES">Spanish</Select.Option>
                    </Select.Content>
                  </Select>
                </Control>
              </Field>
            </Navbar.Item>
          </Navbar.End>
        </Navbar.Menu>
      </Container>
    </Navbar>
  );
}
