import React from 'react';
import { useSelector } from 'react-redux';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Title from 'react-bulma-companion/lib/Title';

export default function LostPage() {
  const { message } = useSelector(state => state.language.data.lost);

  return (
    <div className="lost-page">
      <Section className="full-page">
        <Container>
          <Title className="app-title" size="1">404</Title>
          <p>{message}</p>
        </Container>
      </Section>
    </div>
  );
}
