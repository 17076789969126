import React from 'react';
import { useSelector } from 'react-redux';

import Section from 'react-bulma-companion/lib/Section';
import Title from 'react-bulma-companion/lib/Title';

import ContactSection from '_components/sections/ContactSection';

export default function ContactPage() {
  const { title } = useSelector(state => state.language.data.contact);

  return (
    <div className="contact-page">
      <Section>
        <Title component="h1" size="1">{title}</Title>
      </Section>
      <hr />
      <ContactSection />
    </div>
  );
}
