import { combineReducers } from 'redux';

import language from './language';

const createRootReducer = routerReducer => combineReducers({
  router: routerReducer,
  language,
});

export default createRootReducer;
