import React from 'react';

import Container from 'react-bulma-companion/lib/Container';
import Image from 'react-bulma-companion/lib/Image';

import { googleMapsApiKey } from '_client/secrets';

export default function GoogleMap() {
  return (
    <Container className="iframe-container">
      <Image ratio="4by3">
        <iframe
          title="Google Map"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: '1px solid #4a4a4a', margin: '0 auto' }}
          src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ4bwT36pMW4YRcYDdm7iRzOc&key=${googleMapsApiKey}`}
          allowFullScreen
        />
      </Image>
    </Container>
  );
}
