import React from 'react';

import Section from 'react-bulma-companion/lib/Section';
import Container from 'react-bulma-companion/lib/Container';
import Columns from 'react-bulma-companion/lib/Columns';
import Column from 'react-bulma-companion/lib/Column';

import ContactInfo from '_components/organisms/ContactInfo';
import GoogleMap from '_components/molecules/GoogleMap';

export default function ContactSection() {
  return (
    <Section className="contact-section">
      <Container>
        <Columns>
          <Column size="half">
            <ContactInfo />
          </Column>
          <Column size="half">
            <GoogleMap />
          </Column>
        </Columns>
      </Container>
    </Section>
  );
}
