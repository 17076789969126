import React from 'react';
import { Parallax } from 'react-parallax';
import { useSelector } from 'react-redux';

import lightsImg from '_client/assets/pictures/lights.jpg';

export default function Welcome() {
  const language = useSelector(state => state.language.data);

  return (
    <Parallax bgImage={lightsImg} strength={200}>
      <div className="welcome-section section">
        {language.home.welcome}
      </div>
    </Parallax>
  );
}
